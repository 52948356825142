import React, { Component, Fragment } from 'react';
//import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
//import MyButton from '../../util/MyButton';
//import PostScream from '../scream/PostScream';
import Notifications from './Notifications';
// MUI stuff
//import AppBar from '@material-ui/core/AppBar';
//import Toolbar from '@material-ui/core/Toolbar';
//import Button from '@material-ui/core/Button';
// Icons
//import HomeIcon from '@material-ui/icons/Home';

class Navbar extends Component {
  render() {
    const { authenticated } = this.props;
    return (
      <nav id="menu" className="navbar navbar-default navbar-fixed-top">
        <div className="container">
          <div className="navbar-header">
            <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1"> <span className="sr-only">Toggle navigation</span> <span className="icon-bar"></span> <span className="icon-bar"></span> <span className="icon-bar"></span> </button>
            <a className="navbar-brand page-scroll" href="/"><img src={process.env.PUBLIC_URL +"/img/indiacityscom-logo.jpg"} className="img-responsive" alt="indiacitys.com"/></a> </div>
          
          <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
            <ul className="nav navbar-nav navbar-right">
            <li><a href="/" data-toggle="collapse" data-target=".navbar-collapse.in">Home</a></li>
			        <li><a href="/discuss" data-toggle="collapse" data-target=".navbar-collapse.in">Blog</a></li>
              <li><a href="https://www.indiacities.in" data-toggle="collapse" data-target=".navbar-collapse.in">Community</a></li>
              <li><a href="/#services" data-toggle="collapse" data-target=".navbar-collapse.in">Services</a></li>
              <li><a href="/#about" data-toggle="collapse" data-target=".navbar-collapse.in">About</a></li>			  
              <li><a href="/#contact" data-toggle="collapse" data-target=".navbar-collapse.in">Contact</a></li>
				{authenticated ? (
					<Fragment>
					  <li><Notifications /></li>
					</Fragment>
				) : (
					<Fragment>
						<li><a href="/login">Login</a></li>
					</Fragment>
				)}
		  </ul>
		 </div>
        </div>
      </nav>
    );
  }
}

Navbar.propTypes = {
  authenticated: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => ({
  authenticated: state.user.authenticated
});

export default connect(mapStateToProps)(Navbar);
