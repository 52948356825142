import React, { Component } from 'react'
import { v4 as uuidv4 } from 'uuid';

export class about extends Component {
  render() {
    return (
        <div id="about" key="about">
        <div className="container" key="container"  style={{width:'100%',padding:'15px'}}>
          <div className="row" key="row">
            <div className="col-xs-12 col-md-6" key="div2"> <img src="https://www.indiacitys.com/img/about.jpg" className="img-responsive" alt=""/> </div>
            <div className="col-xs-12 col-md-6" key="div3">
              <div className="about-text" key="aboutdiv">
                <h2>About Us</h2>
                <p>{this.props.data ? this.props.data.paragraph : 'loading...'}</p>
                <h3>Why Choose Us?</h3>
                <div className="list-style" key="liststylediv">
                  <div className="col-lg-6 col-sm-6 col-xs-12" key="div4">
                    <ul key="uldiv4">
                      {this.props.data ? this.props.data.Why.map(d => <li key={uuidv4()}>{d}</li>) : 'loading'}
                    </ul>
                  </div>
                  <div className="col-lg-6 col-sm-6 col-xs-12" key="div5">
                    <ul key="uldiv5">
                    {this.props.data ? this.props.data.Why2.map(d => <li key={uuidv4()}>{d}</li>) : 'loading'}

                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default about
