import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
//import MyButton from '../../util/MyButton';
// MUI Stuff
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
//import Dialog from '@material-ui/core/Dialog';
//import DialogContent from '@material-ui/core/DialogContent';
//import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withRouter } from 'react-router-dom';
//import AddIcon from '@material-ui/icons/Add';
//import CloseIcon from '@material-ui/icons/Close';
// Redux stuff
import { connect } from 'react-redux';
import { postScream, clearErrors } from '../../redux/actions/dataActions';

const styles = (theme) => ({
  ...theme,
  submitButton: {
    position: 'relative',
    float: 'right',
    marginTop: 10
  },
  progressSpinner: {
    position: 'absolute'
  },
  closeButton: {
    position: 'absolute',
    left: '91%',
    top: '6%'
  }
});

class PostScream extends Component {
  state = {
    title: '',
    body: '',
    errors: {}
  };

  componentDidMount(){
    window.scrollTo(0, 0);
  }

  //componentWillReceiveProps(nextProps) {
  //  if (nextProps.UI.errors) {
  //    this.setState({
  //      errors: nextProps.UI.errors
  //    });
  //  }
  //  if (!nextProps.UI.errors && !nextProps.UI.loading) {
  //    this.setState({ title:'', body: '', errors: {} });
  //  }
  //}

  //componentDidUpdate(prevProps){
  //  if(prevProps.UI.errors !== this.props.UI.errors){
  //    this.setState({ title:'', body: '', errors: {} });
  //  }
  //} 

  static getDerivedStateFromProps(nextProps, prevState){
    if(nextProps.UI.errors){
      return { errors: nextProps.UI.errors };
    }
    else
    {
        return null;
    }

  }  

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  handleSubmit = (event) => {
    event.preventDefault();
    console.log(this.state.title+'empty');
    this.props.postScream({ title: this.state.title, body: this.state.body },this.props.history);
  };
  render() {
    const { errors } = this.state;
    const {
      classes,
      UI: { loading },
      user: {
        authenticated
      }      
    } = this.props;
    return (
        <div style={{width:'100%',marginTop:'80px',padding:'15px'}}>
            {authenticated ? (<form onSubmit={this.handleSubmit}>
              <Typography
                color="primary"
                variant="h6"
                align="center"
              >
                Submit your content
              </Typography>  
            <TextField
                name="title"
                type="text"
                label="Enter title"
                multiline
                rows="3"
                placeholder="Title..."
                error={errors.body ? true : false}
                helperText={errors.body}
                className={classes.textField}
                onChange={this.handleChange}
                fullWidth
              />
              <TextField
                name="body"
                type="text"
                label="Enter content"
                multiline
                rows="3"
                placeholder="Content..."
                error={errors.body ? true : false}
                helperText={errors.body}
                className={classes.textField}
                onChange={this.handleChange}
                fullWidth
              />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.submitButton}
                disabled={loading}
              >
                Submit
                {loading && (
                  <CircularProgress
                    size={30}
                    className={classes.progressSpinner}
                  />
                )}
              </Button>
            </form>) : ("Login required to submit the post")}
        </div>
    );
  }
}

PostScream.propTypes = {
  postScream: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired,
  UI: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  UI: state.UI,
  user: state.user
});

export default withRouter(connect(
  mapStateToProps,
  { postScream, clearErrors }
)(withStyles(styles)(PostScream)));
