import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import jwtDecode from 'jwt-decode';
import {isMobile} from 'react-device-detect';
// Redux
import { Provider } from 'react-redux';
import store from './redux/store';
import { SET_AUTHENTICATED } from './redux/types';
import { logoutUser, getUserData } from './redux/actions/userActions';
// Components
import Navbar from './components/layout/Navbar';
import themeObject from './util/theme';
import AuthRoute from './util/AuthRoute';
import IcBottomNavigation from './util/IcBottomNavigation';
// Pages
import home from './pages/home';
import discuss from './pages/discuss';
import discussdetails from './pages/ScreamDialog';
import addListing from './components/scream/PostScream';
import login from './pages/login';
import signup from './pages/signup';
import user from './pages/user';
import icpageNotfound from './pages/icpagenotfound';

import axios from 'axios';

const theme = createMuiTheme(themeObject);

//const cors = require('cors');
//app.use(cors());

//const { db } = require('./util/admin');



axios.defaults.baseURL =
  'https://us-central1-india-cities-forum.cloudfunctions.net/api';

const token = localStorage.FBIdToken;
if (token) {
  const decodedToken = jwtDecode(token);
  if (decodedToken.exp * 1000 < Date.now()) {
    store.dispatch(logoutUser());
    window.location.href = '/login';
  } else {
    store.dispatch({ type: SET_AUTHENTICATED });
    axios.defaults.headers.common['Authorization'] = token;
    store.dispatch(getUserData());
  }
}

class App extends Component {

render() {
    return (
      <MuiThemeProvider theme={theme}>
        <Provider store={store}>
          <Router>
            <Navbar />
            <div className="container" style={{paddingLeft:'0px',paddingRight:'0px'}}>
              <Switch>
                <Route exact path="/" component={home} />
                <Route
                  path="/discuss/:screamId/"
                  component={discussdetails}
                />                  
				        <Route exact path="/discuss/" component={discuss} />
                <AuthRoute exact path="/login/" component={login} />
                <AuthRoute exact path="/signup/" component={signup} />
                <Route
                  path="/users/:handle/discuss/:screamId/"
                  component={user}
                />
                <Route exact path="/users/:handle/" component={user} />
                <Route exact path="/add-listing/" component={addListing} />
                <Route component={icpageNotfound} />
              </Switch>
            </div>
            {isMobile && (<IcBottomNavigation />)}
          </Router>
        </Provider>
      </MuiThemeProvider>
    );
  }
}

export default App;
