import React, { Component } from 'react'
import { v4 as uuidv4 } from 'uuid';

export class Services extends Component {
  render() {
    return (
        <div id="services" className="text-center" key="services">
        <div className="container" key="container"  style={{width:'100%'}}>
          <div className="section-title" key={uuidv4()}>
            <h2>Our Services</h2>
          </div>
          <div className="row" key={uuidv4()}>

          {this.props.data ? this.props.data.map(d => 
          <a href={d.link}>
              <div className="col-md-3" key={uuidv4()}> <i className={d.icon}></i>
              <div className="service-desc" key={uuidv4()}>
                <h3>{d.name}</h3>
                <p>{d.text}</p>
              </div>
            </div>
          </a>
          ): 'loading'}
          
           
          
        
            </div>
          </div>
        </div>
    )
  }
}

export default Services
