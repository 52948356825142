import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
//import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
//import Bptopicdetail from '../components/bpactivity/bptopicdetail';
//import Profile from '../components/profile/Profile';
//import BpactivitySkeleton from '../util/bpactivitySkeleton';
//import Icleftsidebar from '../util/Icleftsidebar';
//import AddNewactivity from '../components/bpactivity/Postbpactivity';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
//homepage-elements
import Header from './header';
import Features from './features';
import About from './about';
import Services from './services';
import Contact from './contact';
import $ from 'jquery';


const styles = () => ({
  palette: {
    primary: {
      light: '#33c9dc',
      main: '#00bcd4',
      dark: '#008394',
      contrastText: '#fff'
    },
    secondary: {
      light: '#ff6333',
      main: '#ff3d00',
      dark: '#b22a00',
      contrastText: '#fff'
    }
  },
  typography: {
    useNextVariants: true
  },
  form: {
    textAlign: 'center'
  },
  image: {
    width: '20%',
    padding:'7px',
    float:'left',
  },
  pageTitle: {
    margin: '10px auto 10px auto'
  },
  textField: {
    margin: '10px auto 10px auto'
  },
  button: {
    marginTop: 20,
    position: 'relative'
  },
  customError: {
    color: 'red',
    fontSize: '0.8rem',
    marginTop: 10
  },
  progress: {
    position: 'absolute'
  },
  invisibleSeparator: {
    border: 'none',
    margin: 4
  },
  visibleSeparator: {
    width: '100%',
    borderBottom: '1px solid rgba(0,0,0,0.1)',
    marginBottom: 20
  },
  paper: {
    padding: 20,
    height:'100%',
    borderRadius: '0px'
  },
  /*profile: {
    '& .image-wrapper': {
      textAlign: 'center',
      position: 'relative',
      '& button': {
        position: 'absolute',
        top: '80%',
        left: '70%'
      }
    },
    '& .profileDivider': {
      marginTop:'20px',
      marginBottom:'20px',
      textAlign:'left',
      padding:'10px'
    },
    '& .profile-image': {
      width: 200,
      height: 200,
      objectFit: 'cover',
      maxWidth: '100%',
      borderRadius: '50%'
    },
    '& .profile-details': {
      marginTop:'5px',
      border:'1px solid #1E7A46',
      whiteSpace: 'pre-line',
      overflow: "hidden",
      textAlign: 'center',
      '& span, svg': {
        verticalAlign: 'middle'
      },
      '& a': {
        color: '#00bcd4'
      }
    },
    '& hr': {
      border: 'none',
      margin: '0 0 10px 0'
    },
    '& svg.button': {
      '&:hover': {
        cursor: 'pointer'
      }
    },
  },*/
  buttons: {
    textAlign: 'center',
    '& a': {
      margin: '20px 10px'
    }
  },
  logoutbutton: {
    float:'left'
  },
  card: {
    position: 'relative',
    marginBottom: 20,
    width:'100%',
    float:'left'
  },
  content: {
    padding: 18,
    objectFit: 'cover',
    whiteSpace: 'pre-line',
    overflow: "hidden",
    width: '100%',
    float:'left',
    border: '1px solid #ccc',
  },
  contentHeader: {
    width:'100%',
    float:'left',
    border: '1px solid #ccc',
    fontWeight:'bold',
    fontSize:'18px',
    padding:'5px'
  },
  contentHeaderText: {
    fontWeight:'bold',
    fontSize:'1.5rem',
    float:'left',
    margin:'5px'
  },
  spantextCenter: {
    textAlign:'center',
  },
  userBlock:{
    border: '1px solid #ccc'
  },
  contentImage: {
    width:'100%',
    maxWidth:'800px',
    objectFit: 'cover'   
  },
  profile: {
    width:'100%',
    float:'left'
  },
  Authorprofile: {
    width:'100%',
    float:'left',
    borderBottom:'1px solid #ccc',
    borderTop:'1px solid #ccc'
  },
  author: {
    marginTop:'25px',
  },
  comments: {
    width:'100%',
    float:'left',
    marginTop:'15px'
  },
  updatedOn: {
    width:'100%',
    float:'left',
    marginBottom:'7px',
    borderTop:'1px solid #ccc',
    fontSize:'12px'
  },
  openinnewButton: {
    marginBottom:'0px',
    paddingBottom:'0px',
    marginTop:'5px'
  },
  expandButton: {
    /*position: 'absolute',
    left: '90%'*/
    float:'right',
    padding:'5px',
    marginBottom:'10px'
  },
  viewMore: {
    fontWeight: '400',
    fontSize: '14px',
    color: 'rgba(0, 0, 0, 0.87)',
    marginBottom:'0px'
  },
});

class icpageNotfound extends Component {

    state = {
		resumeData : {},
	}
	getResumeData(){
		$.ajax({
		url:'/data.json',
		dataType:'json',
		cache: false,
		success: function(data){
			this.setState({resumeData: data});
		}.bind(this),
		error: function(xhr, status, err){
			console.log(err);
			alert(err);
		}
		});
	}
  
  componentDidMount() {
    window.scrollTo(0, 0);
    this.getResumeData();
  }

  render() {
    const {
        classes,
      } = this.props;      
    return (
    <div className="screamcontainer">
	  <div id="discuss" style={{background:"#ffffff"}}>
      <Grid container spacing={16}>
        <Grid item sm={12} xs={12} style={{backgroundColor:'#ffffff'}}>
        <Paper className={classes.paper} style={{borderRadius:'0px',marginTop:'0px',padding:'0px',boxShadow:'none'}}>
              <Typography
                variant="h3"
                color="primary"
                align="center"
              >
                Sorry - Page not found!!!
              </Typography>
            </Paper>
        </Grid>
      </Grid>
      <div>
		<Header key="header" data={this.state.resumeData.Header}/>			
		<Features key="features" data={this.state.resumeData.Features}/>
		<About  key="about" data={this.state.resumeData.About}/>
		<Services  key="services" data={this.state.resumeData.Services}/>
		<Contact  key="contact" data={this.state.resumeData.Contact}/>
	    </div>      
	  </div>
  </div>
    );
  }
}


export default (withStyles(styles)(icpageNotfound));
