import React, { Component } from 'react';
//homepage-elements
import Header from './header';
import Features from './features';
import About from './about';
import Services from './services';
import Contact from './contact';
import $ from 'jquery';
//const styles = (theme) => ({
//  ...theme
// });
class home extends Component {
	
	state = {
		resumeData : {},
	}
	getResumeData(){
		$.ajax({
		url:'/data.json',
		dataType:'json',
		cache: false,
		success: function(data){
			this.setState({resumeData: data});
		}.bind(this),
		error: function(xhr, status, err){
			console.log(err);
			alert(err);
		}
		});
	}

	componentDidMount(){
		window.scrollTo(0, 0);
		this.getResumeData();
	}	

	render() {
	
		return (
			
			<div>
				<Header key="header" data={this.state.resumeData.Header}/>			
				<Features key="features" data={this.state.resumeData.Features}/>
				<About  key="about" data={this.state.resumeData.About}/>
				<Services  key="services" data={this.state.resumeData.Services}/>
				<Contact  key="contact" data={this.state.resumeData.Contact}/>
			</div>
			
		);
	
	}

}
export default home;