import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import PropTypes from 'prop-types';
import MyButton from '../../util/MyButton';
import DeleteScream from './DeleteScream';
//import ScreamDialog from './ScreamDialog';
import LikeButton from './LikeButton';
// MUI Stuff
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
//import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import MuiLink from '@material-ui/core/Link';
// Icons
import ChatIcon from '@material-ui/icons/Chat';
// Redux
import { connect } from 'react-redux';

const styles = {
  card: {
    //position: 'relative',
    //display: 'flex',
    marginBottom: 5
  },
  iclink: {
    width:'100%'
  },
  image: {
    width: 100,
    height:100,
  },
  root: {
    width:'100%',
    float:'left',
    padding: 0,
    objectFit: 'cover',
    whiteSpace: 'pre-line',
    paddingBottom:'10px !important'
      },
  ictitle: {
    padding:'10px'
  },
  iccontent: {
    padding: '10px'
  }
};

class Scream extends Component {
  render() {
    dayjs.extend(relativeTime);
    const {
      classes,
      scream: {
        title,
        body,
        createdAt,
        //userImage,
        userHandle,
        screamId,
        likeCount,
        commentCount
      },
      user: {
        authenticated,
        credentials: { handle }
      }
    } = this.props;

    const deleteButton =
      authenticated && userHandle === handle ? (
        <DeleteScream screamId={screamId} />
      ) : null;

    /*const profileImageStyle = {
      height: 100,
      width: 100,
      borderRadius: '50%',
      objectFit: 'cover',
      marginTop: '30%',
      marginLeft: '15%'
    };*/

    return (
      <Card className={classes.card}>
        <MuiLink
          component={Link}
          to={`/discuss/${screamId}`}
          className={classes.iclink}
        >
          <CardContent className={classes.root}>
            {/* User name */}
            <div className={classes.ictitle}>
              <Typography
                color="primary"
                variant="h6"
                align="center"
              >
                {title}
              </Typography>
            </div>
            <div className={classes.iccontent}>
              <Typography 
                variant="body1"
              >{body}
              </Typography>
            </div>
            </CardContent>
            </MuiLink>
            <CardContent className={classes.root}>
            {/* Like btn */}
            <div style={{borderTop:'1px solid #ccc',marginTop:'10px',padding:'10px'}}>
              <LikeButton screamId={screamId} />
              <span style={{fontSize:'14px'}}>{likeCount} Likes</span>
              {/* Comments btn */}
              <MyButton tip="Comments">
                <ChatIcon color="primary" style={{fontSize:'16px'}}/>
              </MyButton>
              <span style={{fontSize:'14px'}}>{commentCount} Comments</span>
              {deleteButton}
            </div>
            {/*Expand to view post with comments 
            <ScreamDialog
              screamId={screamId}
              userHandle={userHandle}
              openDialog={this.props.openDialog}
            />*/}
            <div style={{borderTop:'1px solid #ccc',padding:'10px'}}>
              <Typography variant="caption" color="textSecondary" align="left">
                {dayjs(createdAt).fromNow()}
              </Typography>
            </div>
          </CardContent>
      </Card>
    );
  }
}

Scream.propTypes = {
  user: PropTypes.object.isRequired,
  scream: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  openDialog: PropTypes.bool
};

const mapStateToProps = (state) => ({
  user: state.user
});

export default connect(mapStateToProps)(withStyles(styles)(Scream));
