import React, { Component } from 'react'

export class features extends Component {
  render() {
    return (
        <div id="features" key="featuresdiv" className="text-center">
        <div className="container" key="featurescontainer" style={{width:'100%'}}>
          <div className="col-md-10 col-md-offset-1 section-title" key="featuressectiontitle">
            <h2 key="featuresh2">Features</h2>
          </div>
          <div className="row" key="featuresrow">
          {this.props.data ? this.props.data.map((d) => 
            <div className="col-md-3" key={d.icon}> <i className={d.icon}></i>
                <h3 >{d.title}</h3>
                <p >{d.text}</p>
            </div>
          ) : 'Loading...'}
          </div>
        </div>
      </div>
    )
  }
}

export default features
