import React, { Component } from 'react';
//import { Link } from 'react-router-dom';
//import { connect } from 'react-redux';
//import PropTypes from 'prop-types';
//import { makeStyles } from '@material-ui/core/styles';
//import MuiLink from '@material-ui/core/Link';
import { withRouter } from 'react-router-dom';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import HomeIcon from '@material-ui/icons/Home';
//import GroupsIcon from '@material-ui/icons/Group';
import AboutIcon from '@material-ui/icons/Web';
import TopicsIcon from '@material-ui/icons/Pages';
import CommunityIcon from '@material-ui/icons/BeachAccess';
import withStyles from '@material-ui/core/styles/withStyles';


const styles = () => ({
  icroot: {
    //width: 500,
    //backgroundColor:'#360a88',
    backgroundColor:'#202661',
    color:'#ffffff',
    width: '100%',
    position: 'fixed',
    bottom: 0,
  },
  icrootnavaction: {
    color:'#ffffff',
  }, 
  icselected: {
      color: '#202661 !important',
      backgroundColor: 'wheat !important'
  }  

});

class IcBottomNavigation extends Component {
  //const classes = useStyles();
  //const [value, setValue] = React.useState('recents');

  state = {
    icvalue: ""
  };

  handleChange = (event, newValue) => {
    var section = null;
    if (newValue === "home"){
      this.props.history.push("/");
    }
    else
    {
      if (newValue === 'community'){
        window.location.assign('https://www.indiacities.in');
      }
      else
      {
        this.props.history.push(`/${newValue}`);
      }
    }
    this.setState({ icvalue: newValue});
    console.log(newValue);
    if (newValue === "#about") {
      section = document.querySelector( '#about' );
      if (section !== null){
        section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
      }
    }
    if (newValue === "home") {
      section = document.querySelector( '#header' );
      if (section !== null){
        section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
      }
    }    
    /*if (newValue === "#about") {
      target = newValue.length ? newValue : $('[name=' + this.hash.slice(1) +']');
      if (target.length) {
        $('html,body').animate({
          scrollTop: target.offset().top - 50
        }, 900);
        return false;
      }
    }*/
  }

  render() {
    const { classes } = this.props;
    //const preventDefault = (event) => event.preventDefault();
    return (
      <BottomNavigation value={this.state.icvalue} onChange={this.handleChange} showLabels className={classes.icroot}>
        <BottomNavigationAction label="Home" value="home" icon={<HomeIcon />} classes={{root:classes.icrootnavaction,selected:classes.icselected}} />
        <BottomNavigationAction label="About" value="#about" icon={<AboutIcon />} classes={{root:classes.icrootnavaction,selected:classes.icselected}} />
        <BottomNavigationAction label="Blog" value="discuss" icon={<TopicsIcon />} classes={{root:classes.icrootnavaction,selected:classes.icselected}} />
        <BottomNavigationAction label="Community" value="community" icon={<CommunityIcon />} classes={{root:classes.icrootnavaction,selected:classes.icselected}} />
      </BottomNavigation>
    );
  }
}


export default withRouter((withStyles(styles)(IcBottomNavigation)));