//import firebase from 'firebase';
import firebase from 'firebase/app';
import "firebase/firestore";
import "firebase/auth";
const config = {
  apiKey: "AIzaSyDfz9vxjqDmt0M5OZbKpuBPdALmL_0PuMw",
  authDomain: "india-cities-forum.firebaseapp.com",
  databaseURL: "https://india-cities-forum.firebaseio.com",
  projectId: "india-cities-forum",
  storageBucket: "india-cities-forum.appspot.com",
  messagingSenderId: "890094529378",
  appId: "1:890094529378:web:9aa7d5aacc33bb5ae65a3b"
};
firebase.initializeApp(config);
const db = firebase.firestore();
const auth = firebase.auth();
export {firebase, db, auth};