import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import MyButton from '../util/MyButton';
import LikeButton from '../components/scream/LikeButton';
import Comments from '../components/scream/Comments';
import CommentForm from '../components/scream/CommentForm';
import dayjs from 'dayjs';
//  import { Link } from 'react-router-dom';
// MUI Stuff
//import Dialog from '@material-ui/core/Dialog';
//import DialogContent from '@material-ui/core/DialogContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
// Icons
//import CloseIcon from '@material-ui/icons/Close';
//import UnfoldMore from '@material-ui/icons/UnfoldMore';
import ChatIcon from '@material-ui/icons/Chat';
// Redux stuff
import { connect } from 'react-redux';
import { getScream, clearErrors } from '../redux/actions/dataActions';

const styles = (theme) => ({
  ...theme,
  profileImage: {
    maxWidth: 60,
    height: 60,
    borderRadius: '50%',
    objectFit: 'cover',
    padding:'5px'
  },
  dialogContent: {
    padding: 20
  },
  closeButton: {
    position: 'absolute',
    left: '90%'
  },
  expandButton: {
    position: 'absolute',
    left: '90%'
  },
  spinnerDiv: {
    textAlign: 'center',
    marginTop: 50,
    marginBottom: 50
  }
});

class ScreamDialog extends Component {

  componentDidMount() {
    window.scrollTo(0, 0);
    const screamId = this.props.match.params.screamId;
    this.props.getScream(screamId);
  }
  //handleOpen = () => {
    //let oldPath = window.location.pathname;

    //const { userHandle, screamId } = this.props;
    //const newPath = `/users/${userHandle}/scream/${screamId}`;

    //if (oldPath === newPath) oldPath = `/users/${userHandle}`;

    //window.history.pushState(null, null, newPath);

    //this.setState({ open: true, oldPath, newPath });
    //this.props.getScream(this.props.screamId);
  //};
  //handleClose = () => {
    //window.history.pushState(null, null, this.state.oldPath);
    //this.setState({ open: false });
    //this.props.clearErrors();
    //window.location.reload();
  //};

  render() {
    const {
      classes,
      data: {scream: {
        screamId,
        title,
        body,
        createdAt,
        likeCount,
        commentCount,
        userImage,
        userHandle,
        comments
      }},
      UI: { loading }
    } = this.props;

    const dialogMarkup = loading ? (
      <div className={classes.spinnerDiv}>
        <CircularProgress size={60} thickness={2} />
      </div>
    ) : (
      <Grid container spacing={16}>
        <Grid item sm={12}>
          <Typography
            color="primary"
            variant="h6"
            align="center"
          >
            {title}
          </Typography>
          <Typography variant="body1">{body}</Typography>
          <Typography variant="caption" color="textSecondary" style={{borderTop:'1px solid #ccc'}}>
            {dayjs(createdAt).format('h:mm a, MMMM DD YYYY')} by @{userHandle} <img src={userImage} alt="Profile" className={classes.profileImage}/>
          </Typography>
          {screamId && (<LikeButton screamId={screamId} />)}
          <span>{likeCount} Likes</span>
          <MyButton tip="Comments">
            <ChatIcon color="primary" />
          </MyButton>
          <span>{commentCount} Comments</span>
        </Grid>
        {screamId && (<CommentForm screamId={screamId} />)}
        {comments && (<Comments comments={comments} />)}
      </Grid>
    );
    return (
      <div className="screamcontainer">
        <div id="discuss" style={{background:'#ffffff',border:'1px solid #ccc',marginTop:'100px'}}>
          {dialogMarkup}
        </div>
      </div>
    );
  }
}

ScreamDialog.propTypes = {
  clearErrors: PropTypes.func.isRequired,
  getScream: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  UI: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  data: state.data,
  UI: state.UI
});

const mapActionsToProps = {
  getScream,
  clearErrors
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(styles)(ScreamDialog));
