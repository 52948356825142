import React, { Component } from 'react'
//import PropTypes from 'prop-types'

export class Contact extends Component {
  //static propTypes = {
//
  //}

  render() {
    return (
        <div>
<div id="contact">
  <div className="container"  style={{width:'100%'}}>
    <h2>Contact us</h2>
    <div className="col-md-12 contact-info">
      <div className="contact-item col-md-4">
        <p><span><i className="fa fa-map-marker"></i> Address</span>{this.props.data ? this.props.data.address : 'loading'}</p>
      </div>
      <div className="contact-item col-md-4">
        <p><span><i className="fa fa-phone"></i> Phone</span> {this.props.data ? this.props.data.phone : 'loading'}</p>
      </div>
      <div className="contact-item col-md-4">
        <p><span><i className="fa fa-envelope-o"></i> Email</span> {this.props.data ? this.props.data.email : 'loading'}</p>
      </div>
    </div>
  </div>
</div>
<div className="col-md-12" id="social-info">
  <div className="row">
    <div className="social">
      <ul>
        <li><a href={this.props.data ? this.props.data.facebook : '/'}><i className="fa fa-facebook"></i></a></li>
        <li><a href={this.props.data ? this.props.data.twitter : '/'}><i className="fa fa-twitter"></i></a></li>
        <li><a href={this.props.data ? this.props.data.youtube : '/'}><i className="fa fa-youtube"></i></a></li>
      </ul>
    </div>
  </div>
</div>
<div id="footer">
  <div className="container text-center">
    <p>&copy;<a href="http://www.templatewire.com" rel="nofollow">indiacitys.com</a></p>
  </div>
</div>  
      </div>
    )
  }
}

export default Contact
